export class Constants {

  public static DOCENTE_RADIX = 'docente';
  public static STUDENTE_RADIX = 'studente';

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Regex = class {
    public static EMAIL = "^[^(!#$%&*+\/=?^`{|}~)]*$";
  }

  static Routing = class {
    public static COOKIE_POLICY = { label: 'Cookie Policy', path: 'cookie-policy', routerLink: ['/cookie-policy'] };
    public static NOT_FOUND = { label: 'Not_found', path: 'not_found', routerLink: ['/not_found'] };
    public static DASHBOARD = { label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard'] };
    public static HOME = { label: 'Home', path: 'home', routerLink: ['/home'] };
    public static LOGIN = { label: 'Login', path: 'login', routerLink: ['/login'] };
    public static DOCENTE = { label: 'Docente', path: 'docente', routerLink: ['/docente'] };
    public static STUDENTE = { label: 'Studente', path: 'studente', routerLink: ['/studente'] };
    public static RESET_PASSWORD = { label: 'Reset Password', path: 'reset-password', routerLink: ['/reset-password'] };
    public static VERIFICA_EMAIL = { label: 'Verifica Email', path: 'verifica-email', routerLink: ['/verifica-email'] };
    public static CAMBIO_EMAIL = { label: 'Cambio Email', path: 'cambio-email', routerLink: ['/cambio-email'] };
    public static REGISTRAZIONE_DOCENTE = { label: 'Registrazione Docente', path: 'registrazione-docente', routerLink: ['/registrazione-docente'] };
    public static REGISTRAZIONE_DOCENTE_STEP_ONE = { label: 'Registrazione docente', path: 'step-one', routerLink: ['/registrazione-docente', 'step-one'] };
    public static REGISTRAZIONE_DOCENTE_STEP_TWO = { label: 'Registrazione Docente', path: 'step-two', routerLink: ['/registrazione-docente', 'step-two'] };
    public static REGISTRAZIONE_DOCENTE_STEP_THREE = { label: 'Inserisci Password', path: 'step-three', routerLink: ['/registrazione-docente', 'step-three'] };
    public static REGISTRAZIONE_DOCENTE_STEP_FOUR = { label: "Completa l'iscrizione", path: 'step-four', routerLink: ['/registrazione-docente', 'step-four'] };
    public static REGISTRAZIONE_DOCENTE_STEP_FIVE = { label: "Scegli Il Tuo Avatar", path: 'step-five', routerLink: ['/registrazione-docente', 'step-five'] };
    public static REGISTRAZIONE_STUDENTE = { label: 'Registrazione Studente', path: 'registrazione-studente', routerLink: ['/registrazione-studente'] };
    public static REGISTRAZIONE_STUDENTE_STEP_ONE = { label: 'Registrazione Studente', path: 'step-one', routerLink: ['/registrazione-studente', 'step-one'] };
    public static REGISTRAZIONE_STUDENTE_STEP_TWO = { label: 'Raccontaci qualcosa', path: 'step-two', routerLink: ['/registrazione-studente', 'step-two'] };
    public static REGISTRAZIONE_STUDENTE_STEP_THREE = { label: 'Preferenze scolastiche', path: 'step-three', routerLink: ['/registrazione-studente', 'step-three'] };
    public static REGISTRAZIONE_STUDENTE_STEP_FOUR = { label: "Scegli Il Tuo Avatar", path: 'step-four', routerLink: ['/registrazione-studente', 'step-four'] };

  }

  static Auth = class {
    public static USER_KEY = "user";
    public static REMEMBER_KEY = "remember";
    public static ENV_KEY = "environment";

  }
  static Storager = class {
    public static USER_KEY = "user";
    public static REGISTRAZIONE_DOCENTE = "registrazioneDocente";
    public static REGISTRAZIONE_STUDENTE = "registrazioneStudente";
  }
  static RegistrazioneService = class {
    public static ALREADY_REGISTERED_URL = (email: string) => { return `/user/already-registered/${email}` };
    public static MATERIE_URL = "/public/materie";
    public static MATERIE_GRUPPI_URL = "/public/materie/gruppi";
    public static COMUNI_URL = "/public/comuni";
    public static TITOLI_URL = "/public/titoli";
    public static SPECIALIZZAZIONI_URL = "/public/specializzazioni";
    public static ISTITUTI_URL = (idComune: string) => { return `/public/comuni/${idComune}/scuole` };
    public static PLESSI_URL = (scuoleId: string) => { return `/public/scuole/${scuoleId}/plessi` };
    public static REGISTRAZIONE_DOCENTE_URL = "/registration/registration-docente";
    public static COMPLETA_REGISTRAZIONE_DOCENTE_URL = "/registration/complete-registration-docente";
    public static RICHIESTA_REGISTRAZIONE_DOCENTE_URL = "/registration/docente-richiesta-iscrizione";
    public static REGISTRAZIONE_STUDENTE_URL = "/public/studenti"
  }

  static ListOfValue = class {
    public static types: Array<any> = [{ name: 'Studente', value: 'studente' }, { name: 'Docente', value: 'docente' }];
    public static images: Array<any> = [{ title: 'Esplora', text: 'Tanti materiali scaricabili per <b> sviluppare in classe la tua didattica orientativa.</b>', path: "../../../assets/images/esplora.svg" }, { title: 'Approfondisci', text: 'Test e questionari per <b> rilevare le competenze cognitive e non-cognitive </b> nelle tue classi.', path: "../../../assets/images/approfondisci.svg" }, { title: 'Valorizza', text: 'Un sistema rapido e personalizzato per <b> certificare le competenze in uscita </b> di studenti e studentesse.', path: "../../../assets/images/valorizza.svg" }]
  }

  static Email = class {
    public static SUPPORT = "mailto:support@futuri.education?Subject=";
    public static COMMUNITY = "mailto:community@futuri.education?Subject=";
  }
}
